exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-language-workshop-js": () => import("./../../../src/pages/language-workshop.js" /* webpackChunkName: "component---src-pages-language-workshop-js" */),
  "component---src-pages-languages-js": () => import("./../../../src/pages/languages.js" /* webpackChunkName: "component---src-pages-languages-js" */),
  "component---src-pages-lore-js": () => import("./../../../src/pages/lore.js" /* webpackChunkName: "component---src-pages-lore-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */)
}

